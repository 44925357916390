// Gatsby supports TypeScript natively!
import React, { useRef } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

/*
#51255e = purple
#c8514b = red/orange
#f4cd62 = yellow

dark blue = #1c2b3d
real dark blue = #0f2034
light blue = #3c4b62
*/


type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
        <Layout location={location} title={siteTitle}>
          {/* <div style={{width: 400, height: 400, background: `url(${data.hero_image.childImageSharp.fixed})`}}></div> */}
          <SEO title="Dev Internship Dotcom - About Us" />
          <h2>About</h2>
          <p>We're not the world's best engineers. In fact, my name is Jake Lingwall, and it's likely you've never even heard of me before. If you look up my github profile, you wouldn't be impressed. I'm not considered a thought leader, and I don't delve into spicy twitter threads (that don't have to do with my college football team anyway).</p>
          <p>Dev Internship Dotcom isn't about me, my reputation, or background. It's not about the partners working with me on it (although they may be the best engineers in the world...). What it's about is offering a unique learning experience to anyone/everyone who is interested in a different approach.</p>
          <p>We've found that we learn the best by doing. And we've found that when it comes to growing a career in this industry, the most difficult part (by far) is early on. Many engineers don't consider their career, or the business, when looking for online content. And almost every course/resource out there for engineers tends to focus only on syntax. So we set off to create a resource to help people gain experience, while learning more than just syntax.</p>
          <p>And so here we are. We've built the experience we wished we could have had to help people learn what working at a modern software company might be like (they're all different). And we've designed content that is different than anything out there, which we deliver in tandem with thoughts on business/career/technology as we go. It mimics very closely how we like to work with interns/juniors/other engineers in real life. In fact, we wanted this entire internship to feel like one really long "pairing" session.</p>
          <p>So that's what we've built. We think it'll be valuable for you, even if it's coming from us (not the world's best engineers). Although, to be honest, I've found in my career that I can learn something from just about anyone, no matter what their background and experience is. So while we're not rockstars on github, we've done plenty of other things that give us perspective to share, such as: worked at some of the largest corporations on earth, founded startups, taught at bootcamps, grown other startups from 0 engineer to helping them raise millions of dollars, worked with remote engineers, lead teams, lead departments, architected enterprise software used by the largest companeies on the planet, hired, trained, fired, and just about everything else that comes along with the journey of working at a software company.</p>
          <p>Again, Dev Internship Dotcom isn't about us. We didn't build it for us. We built it for you. So we hope you go on this journey with us and learn something along the way. We hope that we can form a bond as go about working together, even if it's async and over the internet.</p>
        </Layout>

  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
